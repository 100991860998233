import { Button, Link, Stack, Typography } from "@mui/material";
import {
  MEDIA,
  POEM,
  POEM_VAULT_LINK,
  SOLANA_EXPLORER,
  WEN_MINT,
  WEN_NFT_MINT,
  WEN_TWEET_LINK,
} from "../../constants";
import { Column, Image, MediaStack, Row } from "../common";
import { LIGHT_SHADOW, formatAddress } from "../theme";
import { useHomeScrollContext } from "../../contexts";
import labeltop from "../containers/boar_logo.png";
import tokenomicschart from "./TokenomicsChart.png"


const ASPECT_RATIO = 1280 / 728;
const WIDTH = { mobile: 200, desktop: 400 };
export default function About() {
  const { aboutRef } = useHomeScrollContext();
  return (
    <Stack ref={aboutRef}>
      <MediaStack
        sxBelow={{ flexDirection: "column" }}
        sxAbove={{ alignItems: "flex-start" }}
        spacing={1}
      >
        <Column spacing={1}>
        <div style={{ borderRadius: '10px', overflow: 'hidden', width: '200px', height: '200px' }}> {/* Apply the borderRadius to the wrapping div */}
  <Image
    variant="fixed-width"
    width="auto"
    src={tokenomicschart}
  />
</div>
          <Button
            sx={{ width: "max-content", marginTop: "2em", marginBottom:"0.5em" }}
            onClick={() => window.open(POEM_VAULT_LINK)}
          >
            GET $BCat
          </Button>
<div>
  <h1> Tokenomics</h1>
</div>
<div>
  1,000,000,000 Supply
</div>
<div>
  0% Tax
</div>
        </Column>
        <iframe id="dextools-widget"
    title="DEXTools Trading Chart"
    width="100%" height="500px"
    src="https://www.dextools.io/widget-chart/en/base/pe-light/0xe8259e9f19d26e400eb4a2f824f77f314e7bfd23?theme=dark&chartType=2&chartResolution=30&drawingToolbars=false"></iframe>
        
        </MediaStack>
        <div style={{ borderRadius: '10px', overflow: 'hidden', width: '200px', height: '100px' }}> </div>
        <iframe _ngcontent-ng-c2039025727=""
         width="100%" height="700px"
         src="https://app.uniswap.org/swap?chain=base&amp;outputCurrency=0x1bdc6c374141e2bc68f0f4138391a8adf15ea9d8" className="ng-star-inserted"></iframe>
      
    </Stack>
  );
}

const Logo = () => (
    <img alt="Top left label" src={labeltop} width="80%" height="auto" />

);

const bullets = [
  "",
];

function Description() {
  return (
    <Column spacing={1}>

    </Column>
  );
}

const TOKENS = [
  { description: "ITEM", item1: "Team Allocation", item2: "Circulating", item3: "Liquidity Pool (Burnt)"},
  { description: "ALLOCATION", item1: "0g", item2: "440g", item3: "60g"},
  { description: "PERCENTAGE", item1: "0%", item2: "88%", item3: "12%"},
];
function TokenLinks() {
  return (
    <MediaStack query={MEDIA.SM} spacing={3}>
      {TOKENS.map(({ description, item1, item2, item3}, i) => (
        <Column key={i}>
          <Typography color="text.primary"> {description} </Typography>
          <Typography color="text.secondary"> {item1} </Typography>
          <Typography color="text.secondary"> {item2} </Typography>
          <Typography color="text.secondary"> {item3} </Typography>
        </Column>
      ))}
    </MediaStack>
  );
}
