import React, { useState } from 'react';
import { Button, Stack } from "@mui/material";
import { Column } from "../common";
import { useHomeScrollContext } from "../../contexts";
import { Gallery } from "react-grid-gallery";
import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css";
import { images, CustomImage } from "../home/images";

function ArrowPrompt() {
  const { scrollTo } = useHomeScrollContext();
  return (
    <Button onClick={scrollTo.secondOnPage} variant="text">
      {'help mi'}
    </Button>
  );
}

export default function Foundation() {
  const { foundationRef } = useHomeScrollContext();
  const [index, setIndex] = useState(-1);

  const currentImage = images[index];
  const nextIndex = (index + 1) % images.length;
  const nextImage = images[nextIndex] || currentImage;
  const prevIndex = (index + images.length - 1) % images.length;
  const prevImage = images[prevIndex] || currentImage;

  const handleClick = (index: number, item: CustomImage) => setIndex(index);
  const handleClose = () => setIndex(-1);
  const handleMovePrev = () => setIndex(prevIndex);
  const handleMoveNext = () => setIndex(nextIndex);

  return (
    <Stack spacing={4} ref={foundationRef}>
      <Column spacing={1} sx={{ justifyContent: 'center' }}>
        <Gallery images={images} onClick={handleClick} enableImageSelection={false} />
        {!!currentImage && (
          <Lightbox
            mainSrc={currentImage.original}
            imageTitle={currentImage.caption}
            mainSrcThumbnail={currentImage.src}
            nextSrc={nextImage.original}
            nextSrcThumbnail={nextImage.src}
            prevSrc={prevImage.original}
            prevSrcThumbnail={prevImage.src}
            onCloseRequest={handleClose}
            onMovePrevRequest={handleMovePrev}
            onMoveNextRequest={handleMoveNext}
          />
        )}
        <ArrowPrompt />
      </Column>
    </Stack>
  );
}