import { Image } from "react-grid-gallery";

export interface CustomImage extends Image {
  original: string;
}

export const images: CustomImage[] = [
  {
    src: "./bc1.jpg",
    original: "./bc1.jpg",
    width: 320,
    height: 174,
  },
  {
    src: "./bc5.jpg",
    original: "./bc5.jpg",
    width: 320,
    height: 212,
  },
  {
    src: "./bc2.jpg",
    original: "./bc2.jpg",
    width: 320,
    height: 212,
  },
  {
    src: "./bc3.jpg",
    original: "./bc3.jpg",
    width: 320,
    height: 213,
  },
  {
    src: "./bc4.jpg",
    original: "./bc4.jpg",
    width: 320,
    height: 183,
  },
];