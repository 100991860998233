import { Column, Image } from "../common";
import HERO_LOGO from "../containers/boar_logo.png";
import background from "../containers/boar_bg.png"
import { Button, Stack, Typography } from "@mui/material";
import { useHomeScrollContext } from "../../contexts";
import { shakeAnimation } from "../../util";

export default function Hero() {
  return (
    <Column
      sx={{ py: "100px" }}
      alignItems="center"
      textAlign="center"
      spacing={4}
    >
      <Logo />
      <Typography variant="h3">
        
      </Typography>
    </Column>
  );
}

function Logo() {
  const { scrollTo } = useHomeScrollContext();

  return (
    <div>
    <Stack sx={{ cursor: "pointer" }} onClick={scrollTo.firstOnPage}>
      
        <Image
          src={HERO_LOGO}
          mobileHeight="400px"
          height="500px"
          sx={{
            ":hover": { animation: `${shakeAnimation} 0.5s linear infinite` },
          }}
          variant="fixed-height"
        />

    <Column spacing={1}>
    <Column spacing={1}>
      <Typography variant="h1">Im stuck!</Typography>


    </Column>
    <ArrowPrompt/>
    </Column>

    </Stack>
    </div>
  );
}

function ArrowPrompt() {
  const { scrollTo } = useHomeScrollContext();
  return (
    <Button
      onClick={scrollTo.firstOnPage}
      variant="text"
    >
      {'Im stuck in the burrito!'}
    </Button>
  );
}
