import { WEN_MINT } from "./program";

export const WEN_TWEET_LINK =
"https://twitter.com/burritocatbase";
export const WEN_BURN_TWEET_LINK =
"https://twitter.com/burritocatbase";
export const SOLANA_EXPLORER = "https://www.dextools.io/app/en/base/pair-explorer/0xe8259e9f19d26e400eb4a2f824f77f314e7bfd23?t=1712884352773";
export const CLAIM_AIRDROP_LINK = "https://www.dextools.io/app/en/base/pair-explorer/0xe8259e9f19d26e400eb4a2f824f77f314e7bfd23?t=1712884352773";
export const BUY_WEN_LINK = "https://www.dextools.io/app/en/base/pair-explorer/0xe8259e9f19d26e400eb4a2f824f77f314e7bfd23?t=1712884925236";
export const WEN_TWITTER_LINK = "https://twitter.com/burritocatbase";
export const WEN_TELEGRAM_LINK = "https://t.me/burritocat_entry";
export const BIRDEYE_LINK = "https://birdeye.so/token/0x1BdC6c374141E2BC68F0F4138391a8ADF15Ea9d8?chain=base";
export const POEM_VAULT_LINK =
  "https://www.dextools.io/app/en/base/pair-explorer/0xe8259e9f19d26e400eb4a2f824f77f314e7bfd23?t=1712884925236";
export const ASSET_DASH_ELEMENTS_LINK =
"https://twitter.com/burritocatbase";
export const BRAND_LINK =
  "https://t.me/burritocatmemes";

export const WEN_GITHUB_LINK = "https://t.me/burritocatmemes";
export const WNS_REPO_LINK =
  "https://t.me/burritocatmemes";
export const DEV_DOCS_LINK = "https://t.me/burritocatmemes";
export const WNS_INTEREST_FORM_LINK = "https://www.dextools.io/app/en/solana/pair-explorer/5pJJWH1BWSxrkZejtPXgvPsyWiiP8GKtR4PbiAGLyH5q";
export const UNBOTHERED_LINK = "https://t.me/burritocatmemes";
export const REFRESHING_LINK = "https://t.me/burritocatmemes";
export const REVITALISING_LINK = "https://t.me/burritocatmemes";
export const MOISTURISING_LINK = "https://t.me/burritocatmemes";
export const HYDRATING_LINK = "https://t.me/burritocatmemes";